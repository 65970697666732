// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'pl',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '827a828e5f879f61250846d24d1ae1d08db7f2fb',
    releaseTagRef: '929553d',
    releaseTimestamp: '1734113701',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: 'live_WUWGG77E4BBZLHAZQGUC6XBGFYVQT355',
      environment: 'live',
      urlPrefix: 'https://checkoutshopper-live.adyen.com',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: 'aae115cbea72d5a5d2c64450b95116b8',
      branch: '',
      braze: '57174d09-950d-4dbe-9151-5919e12c2236',
      brazeCluster: 'sdk.fra-02.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyBMghszWXjBYshUkzStvbIIzvHtv8JVZno',
      tomtom: '',
      launchDarkly: '6446875a9b494512705e6488',
      launchDarklyMobileKey: 'mob-b3b1b1ed-16d4-4a61-8b1d-3b0a600251b7',
      mParticle: 'eu1-6030ed4c2ad0cf44b19734c2b686a76c',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: 'merchant.com.bk.native.aq.prod',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-bk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '62ev1gv53cp9bckrt5gdinrqmj',
      userPoolId: 'eu-central-1_dwCOsnxKY',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: '',
        dataCenter: '',
      },
      rum: {
        applicationId: '',
        clientToken: '',
        dataCenter: '',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: '6ebc7d54b003',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: '',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.iad-05.braze.com/api/v3',
      firstData: 'https://api.payeezy.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: 'C0004',
          consentCategoryBraze: 'C0002',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: '',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '018f95d5-243a-7ef4-970b-9424b896be77',
        },
      },
    }
  };